import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";

const Dropdown = ({ options, dropDownbtnEC, dropDownIconEC }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("");

  const toggleDropdown = (event) => {
    event.preventDefault();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  return (
    <div className="relative w-full">
      <button
        onClick={toggleDropdown}
        className={`border-b border-[#000000] outline-0 bg-transparent py-2 text-[15px] text-left w-full text-[#10242C] ${dropDownbtnEC}`}
      >
        {selectedOption ? selectedOption : "Please Select"}
        <FontAwesomeIcon
          icon={faAngleDown}
          className={`absolute right-3 top-1/2 transform -translate-y-1/2 text-[#10242C] ${dropDownIconEC}`}
        />
      </button>
      {isOpen && (
        <div className="absolute mt-1 w-full bg-white rounded-[10px] shadow-lg z-10">
          <ul className="py-2">
            {options.map((option, index) => (
              <li
                key={index}
                className={`px-3 py-2 text-black cursor-pointer ${
                  selectedOption === option
                    ? "bg-[#1c5c4f] text-white"
                    : "hover:bg-[#1c5c4f] hover:text-white"
                }`}
                onClick={() => handleOptionClick(option)}
              >
                {option}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
